
.wordGradient{
  background: linear-gradient(to right, #3F32A0,#3F32A0, #2DD9F1,#2DD9F1);
  -webkit-background-clip: text;
  background-clip: text;
  /* Set the text color to transparent */
  color: transparent;
}

/* styles.css */


.radial-gradient {
  background: radial-gradient(circle  at 2% 22% 91%, rgba(77,118,244,69%),rgba(77,118,244,34%),rgba(77,118,244,0%));
}


.videoBackground {

  background-image: linear-gradient(45deg,rgba(0,0,0, 1),rgba(21,24,28,0.8),rgba(21,24,28,0.8) ,rgba(0,0,0, 1));
  
 }
.curve{
  border-radius: 2rem;
}

.buttonclass {
  padding: 1px 1px; /* Adjust as needed */
  background-image: linear-gradient(to bottom right,#363183,#2C4E97,#00D1EF,#00D1EF );
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
 }


 .profileclass {
  padding: 1px 1px; /* Adjust as needed */
  background-image: linear-gradient(to bottom right,#363183,#2C4E97,#00D1EF,#00D1EF );
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
 }



.width{
  margin-left: 0;
  margin-right: 0;
}

@media (min-width: 1536px) {
  .width {
    max-width: 1536px;
    margin-left: auto;
    margin-right: auto;
  }
}


.bg-custom {
  background-image: url('data:image/svg+xml;base64,...'); /* Fallback image for small screens */
}

@media (min-width: 768px) {
  .bg-custom {
    background-image: url('data:image/svg+xml;base64,...'); /* Background image for md screens */
  }
}

@media (min-width: 1536px) {
  .bg-custom {
    background-image: url('data:image/svg+xml;base64,...'); /* Background image for 2xl screens */
  }
}

